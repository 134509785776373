import axios from 'axios';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { getAPIAuthKey, getHost } from '../../common-utils/apiconfig';
import DetailPage from './detailPage/detailPage';
import './openLeads.css';
import { useSelector, useDispatch } from 'react-redux';
import { retrieveAllNotes } from '../../store/notes/actionNotes';
import * as translationUtils from '../../common-utils/translation_utils';
import { getLeads } from '../../common-utils/salesLeadsApi';

const OpenLeadsNew = ({
  page,
  setPage,
  setOpenAction,
  setLeadDetails,
  dataS,
  setwonCount,
  setretireCount,
  setallCount,
  setallOpenCount,
  isRetireRejectSubmited,
  onClickSave,
  handleClose,
  setIsCommonLoading,
  setVal,
  setIdKey,
  updatedDealSize,
  setUpdatedDealSize,
  setAlertStatus,
  setAlertMessage,
  setModalIsOpen,
  setLeadSource,
  leadSource,
  leadRank,
  setLeadRank,
}) => {
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keyNumber, setKeyNumber] = useState();
  const [key, setKey] = useState();
  const [refreshList, setRefreshList] = useState('');
  const [noteloading, setNoteLoading] = useState(false);
  const [distAccountNumber, setDistAccountNumber] = useState();

  let customerId = '';
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const cid = urlParams.get('cid')
    ? urlParams.get('cid')
    : window.myparkerCI || '';
  // console.log(cid);

  //console.log("All location- ", distAccountNumber);
  if (cid === 'all') {
    customerId = window.sessionStorage.getItem('completeOrgLegalId');
    //     const fruits1 = window.sessionStorage.getItem('completeOrgLegalId');
    // const fruits = fruits1.split(',');
    // const flist = fruits.push("'8898'");
  } else {
    customerId = "'" + window.sessionStorage.getItem('customerId') + "'";
  }

  const [totalRecords, setTotalRecords] = useState({});
  const [offset, setOffset] = useState(0);
  const [lastRecord, setLastRecord] = useState(25);

  // const countPerPage = 10;
  const [nextOecOffset, setNextOecOffset] = useState(0);
  const [nextOecLimit, setNextOecLimit] = useState(0);
  const [nextSfOffset, setNextSfOffset] = useState(0);
  const [nextSfLimit, setNextSfLimit] = useState(0);
  const [isLastPage, setIsLastPage] = useState('false');
  const [prevSfOffset, setPrevSfOffset] = useState([]);
  const [prevOecOffset, setPrevOecOffset] = useState([]);
  const [servPrevSfOffset, setServPrevSfOffset] = useState([]);
  const [servPrevOecOffset, setServPrevOecOffset] = useState([]);
  const [currOecOffset, setCurrOecOffset] = useState(0);
  const [currSfOffset, setCurrSfOffset] = useState(0);

  useEffect(() => {
    console.log('prevSfOffset', prevSfOffset);
    console.log('prevOecOffsets', prevOecOffset);
  }, [prevOecOffset, prevSfOffset]);
  const changeOffset = (p) => {
    getList();
    //setOffset(lastRecord * (p - 1));
    // if (p - 1 === 1) {
    //   setPrevSfOffset([0]);
    //   setPrevOecOffset([0]);
    // }
  };
  useEffect(() => {
    getList();
  }, []);
  const handleFirstPageClick = () => {
    setNextOecOffset(0);
    setNextSfOffset(0);
    setNextOecLimit(0);
    setNextSfLimit(0);
    setIsLastPage('false');
    setPrevOecOffset([]);
    setPrevSfOffset([]);
    setCurrOecOffset(0);
    setCurrSfOffset(0);
  };

  const handlePreviousPageClick = () => {
    // check server previous offset is set or not
    if (servPrevOecOffset.length > 0 && servPrevSfOffset.length > 0) {
      const prevSfOffsetVal = servPrevSfOffset.pop();
      const prevOecOffsetVal = servPrevOecOffset.pop();
      console.log('prevSfOffsetVal', servPrevSfOffset, prevSfOffsetVal);
      console.log('prevOecOffsetVal', servPrevOecOffset, prevOecOffsetVal);
      setNextOecOffset(prevOecOffsetVal);
      setNextSfOffset(prevSfOffsetVal);
    }
    //Retrieve last values from prev array

    const lastPrevSfOffset = prevSfOffset[prevSfOffset.length - 1];
    const lastPrevOecOffset = prevOecOffset[prevOecOffset.length - 1];

    // Update offsets with previous value
    setNextSfOffset(lastPrevSfOffset);
    setNextOecOffset(lastPrevOecOffset);
    // Remove last values from prev array
    setPrevSfOffset(prevSfOffset.slice(0, -1));
    setPrevOecOffset(prevOecOffset.slice(0, -1));
    console.log('prevSfOffset===', prevSfOffset);
    console.log('prevOecOffset===', prevOecOffset);
    console.log('================');
    console.log('NextSfOffset===', nextSfOffset, lastPrevSfOffset);
    console.log('NextOecOffset===', nextOecOffset);
    setIsLastPage('false');

    if (prevOecOffset.length <= 1) {
      setCurrOecOffset(0);
      setCurrSfOffset(0);
    }
  };

  const handleNextPageClick = () => {
    // Update session array

    setPrevSfOffset([...prevSfOffset, currSfOffset]);
    setPrevOecOffset([...prevOecOffset, currOecOffset]);

    setCurrSfOffset(nextSfOffset);
    setCurrOecOffset(nextOecOffset);

    // Increment offsets for next page
    //    setNextOecOffset(nextOecOffset + nextOecLimit);
    //  setNextSfOffset(nextSfOffset + nextSfLimit);

    console.log('prevSfOffset===', prevSfOffset);
    console.log('prevOecOffset===', prevOecOffset);
  };

  const handleLastPageClick = () => {
    setIsLastPage('true');
  };

  //console.log('offset=========', offset)
  const getList = () => {
    let _isMounted = true;
    setIsCommonLoading(true);
    axios(
      getHost() +
        getLeads +
        `/Limit/${lastRecord}/totalResults/true/statusCode/${dataS}/nextOecOffset/${nextOecOffset}/nextOecLimit/${nextOecLimit}/nextSfOffset/${nextSfOffset}/nextSfLimit/${nextSfLimit}/isLastPage/${isLastPage}`,
      //`https://apidev.parker.com/dev/LeadsApi/api/Leads/GetAllOpenLeads/Limit/25/totalResults/true/statusCode/${dataS}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'ocp-apim-subscription-key': getAPIAuthKey(),
        },
        data: {
          distributorAccountNumber_c: customerId,
          orderBy: 'CreationDate:desc',
          userEmail: window.sessionStorage.getItem('oktaLogin'),
        },
      }
    )
      .then((result) => {
        if (
          _isMounted &&
          result.data !== null &&
          result.data !== undefined &&
          result.data.status === 1
        ) {
          // to render all data from api
          // console.log("leads-", result.data);
          setRowData(result?.data?.response?.items);
          setTotalRecords(result?.data?.response?.totalResults);
          //setLimit(limit*2)
          if (dataS === 'allleads') {
            setallCount(result?.data?.response?.totalResults);
            setNextOecOffset(result?.data?.response?.nextOecOffset);
            setNextOecLimit(result?.data?.response?.nextOecLimit);
            setNextSfOffset(result?.data?.response?.nextSfOffset);
            setNextSfLimit(result?.data?.response?.nextSfLimit);
            if (result?.data?.response?.prevOecOffsets.length > 0) {
              setPrevOecOffset(result?.data?.response?.prevOecOffsets);
            }
            if (result?.data?.response?.prevSfOffsets.length > 0) {
              setPrevSfOffset(result?.data?.response?.prevSfOffsets);
            }
          } else if (dataS === 'openleads') {
            setallOpenCount(result?.data?.response?.totalResults);
            setNextOecOffset(result?.data?.response?.nextOecOffset);
            setNextOecLimit(result?.data?.response?.nextOecLimit);
            setNextSfOffset(result?.data?.response?.nextSfOffset);
            setNextSfLimit(result?.data?.response?.nextSfLimit);
            if (result?.data?.response?.prevOecOffsets.length > 0) {
              setPrevOecOffset(result?.data?.response?.prevOecOffsets);
            }
            if (result?.data?.response?.prevSfOffsets.length > 0) {
              setPrevSfOffset(result?.data?.response?.prevSfOffsets);
            }
          } else if (dataS === 'RETIRED') {
            setretireCount(result?.data?.response?.totalResults);
            setNextOecOffset(result?.data?.response?.nextOecOffset);
            setNextOecLimit(result?.data?.response?.nextOecLimit);
            setNextSfOffset(result?.data?.response?.nextSfOffset);
            setNextSfLimit(result?.data?.response?.nextSfLimit);
            if (result?.data?.response?.prevOecOffsets.length > 0) {
              setPrevOecOffset(result?.data?.response?.prevOecOffsets);
            }
            if (result?.data?.response?.prevSfOffsets.length > 0) {
              setPrevSfOffset(result?.data?.response?.prevSfOffsets);
            }
          } else {
            setwonCount(result?.data?.response?.totalResults);
            setNextOecOffset(result?.data?.response?.nextOecOffset);
            setNextOecLimit(result?.data?.response?.nextOecLimit);
            setNextSfOffset(result?.data?.response?.nextSfOffset);
            setNextSfLimit(result?.data?.response?.nextSfLimit);
            if (result?.data?.response?.prevOecOffsets.length > 0) {
              setPrevOecOffset(result?.data?.response?.prevOecOffsets);
            }
            if (result?.data?.response?.prevSfOffsets.length > 0) {
              setPrevSfOffset(result?.data?.response?.prevSfOffsets);
            }
          }
          setIsCommonLoading(false);
        } else {
          if (dataS === 'allleads') {
            setallCount(0);
          } else if (dataS === 'openleads') {
            setallOpenCount(0);
          } else if (dataS === 'RETIRED') {
            setretireCount(0);
          } else {
            setwonCount(0);
          }
          //sessionStorage.clear();
          //window.location.href=`${window.allData.loginUrl}${window.location.href}`;
        }
      })
      .catch((error) => {
        if (dataS === 'allleads') {
          setallCount(0);
        } else if (dataS === 'openleads') {
          setallOpenCount(0);
        } else if (dataS === 'RETIRED') {
          setretireCount(0);
        } else {
          setwonCount(0);
        }
        // sessionStorage.clear();
        // window.location.href=`${window.allData.loginUrl}${window.location.href}`;
      });
  };

  // useEffect(() => {
  //   getList();
  // }, [offset, lastRecord]);

  //const getLeadData = async () => {};

  const customStyles = {
    tableWrapper: {
      style: {
        border: '#e2e5e8 solid 1px',
      },
    },

    headRow: {
      style: {
        minHeight: '25px', // override the row height
      },
    },
    rows: {
      style: {
        minHeight: '40px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        backgroundColor: '#faf9f9',
        fontWeight: 500,
        // minWidth: "150px !important",
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
        // minWidth: "150px !important",
        fontWeight: 500,
        div: {
          whiteSpace: 'break-spaces !important',
        },
      },
    },
  };
  const columns = [
    {
      name: translationUtils.getTranslation(3259, 'Lead Number'),
      selector: (row) => row.keyNumber,
      sortable: true,
      button: true,
      cell: (row) => (
        <a
          href='#!'
          className='anchor-text'
          onClick={() => viewServiceReq(row, row.sourceSystem)}>
          {row.keyNumber}
        </a>
      ),
    },
    {
      name: translationUtils.getTranslation(65, 'Date'),
      cell: (row) => <span>{getFormattedDate(row.creationDate)}</span>,
      selector: (row) => row?.creationDate,
      sortable: true,
    },
    {
      name: translationUtils.getTranslation(1842, 'Status'),
      sortable: true,

      selector: (row) => row.status || 'N/A',
    },
    {
      name: translationUtils.getTranslation(3260, 'Retired Reason'),
      selector: (row) => row.retiredReason || 'N/A',
      sortable: true,
      button: true,
      title: translationUtils.getTranslation(3260, 'Retired Reason'),
    },
    {
      name: translationUtils.getTranslation(355, 'Name'),
      selector: (row) => row.name || 'N/A',
      sortable: true,
    },
    {
      name: translationUtils.getTranslation(120, 'Company'),
      sortable: true,
      selector: (row) => row.company || 'N/A',
    },
    {
      name: translationUtils.getTranslation(114, 'City'),
      sortable: true,
      selector: (row) => row.city || 'N/A',
    },
    {
      name: translationUtils.getTranslation(3261, 'St'),
      sortable: true,
      selector: (row) => row.st || 'N/A',
      minWidth: '20px !important',
    },
    {
      name: translationUtils.getTranslation(443, 'Zip'),
      sortable: true,
      selector: (row) => row.zip || 'N/A',
      minWidth: '20px !important',
    },
    {
      name: translationUtils.getTranslation(3262, 'Source'),
      sortable: true,
      selector: (row) => row.source || 'N/A',
    },
    {
      name: translationUtils.getTranslation(218, 'Products'),
      sortable: true,
      selector: (row) => row.product || 'N/A',
    },
    {
      name: translationUtils.getTranslation(3263, 'Dist Sales Person'),
      sortable: true,
      button: true,
      selector: (row) => row.distSalesPerson || 'N/A',
    },
    {
      name: translationUtils.getTranslation(3279, 'Account') + '#',
      sortable: true,
      selector: (row) => row.account || 'N/A',
    },
  ];
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const getFormattedDate = (newDate) => {
    let objectDate = new Date(newDate);
    let day = objectDate.getDate();
    let month = monthNames[objectDate.getMonth()];
    let year = objectDate.getFullYear();
    let format1 = month + '/' + day + '/' + year;
    return format1;
  };

  const viewServiceReq = (row, ls) => {
    setKeyNumber(row?.keyNumber);
    setPage('summary');
    setOpenAction(true);
    setLeadDetails(row);
    setLeadSource(ls);
    setKey(row?.keyNumber);
    //console.log("Source system==", row?.sourceSystem);
  };
  const [items, setItems] = useState([]);

  useEffect(() => {
    const items = sessionStorage.getItem('completeOrgLegalId');
    if (items) {
      setItems(items);
    }
  }, []);

  useEffect(() => {
    setNoteLoading(true);
    //setDistAccountNumber(window.sessionStorage.getItem('customerId'));

    if (
      typeof key !== 'undefined' &&
      sessionStorage.getItem('completeOrgLegalId')
    ) {
      let orgList =
        sessionStorage.getItem('completeOrgLegalId') +
        ",'" +
        distAccountNumber +
        "'";
      let payload = {
        distributorAccountNumber_c: orgList,
        noteTypeCode: 'GENERAL',
        offset: 0,
        limit: 1000,
        userEmail: window.sessionStorage.getItem('oktaLogin'),
      };

      dispatch(retrieveAllNotes({ leadSource, key, payload }));
    }
  }, [key, refreshList, distAccountNumber]);

  return (
    <>
      {page === 'open' ? (
        <div className='openLeadsWrapper'>
          {loading ? (
            <img src='../assets/loading.gif' alt='loader' />
          ) : (
            <DataTable
              fixedHeader
              fixedHeaderScrollHeight='600px'
              columns={columns}
              data={rowData}
              customStyles={customStyles}
              pagination
              paginationServer
              paginationTotalRows={totalRecords}
              paginationPerPage={lastRecord}
              paginationIconFirstPage={
                <div
                  className='customIcon'
                  onClick={() => {
                    console.log('First page button clicked');
                    handleFirstPageClick();
                  }}>
                  <i className='fa fa-step-backward' aria-hidden='true'></i>
                </div>
              }
              paginationIconPrevious={
                <div
                  className='customIcon'
                  onClick={() => {
                    console.log('Previous page button clicked');
                    handlePreviousPageClick();
                  }}>
                  <i className='fa fa-chevron-left' aria-hidden='true'></i>
                </div>
              }
              paginationIconNext={
                <div
                  className='customIcon'
                  onClick={() => {
                    console.log('Next page button clicked');
                    handleNextPageClick();
                  }}>
                  <i className='fa fa-chevron-right' aria-hidden='true'></i>
                </div>
              }
              paginationIconLastPage={
                <div
                  className='customIcon'
                  onClick={() => {
                    console.log('Last page button clicked');
                    handleLastPageClick();
                  }}>
                  <i className='fa fa-step-forward' aria-hidden='true'></i>
                </div>
              }
              paginationComponentOptions={{
                noRowsPerPage: true,
                onPageChange: (page) => {
                  console.log(`Page changed to ${page}`);
                },
                onRowsPerPageChange: (currentRowsPerPage) => {
                  console.log(`Rows per page changed to ${currentRowsPerPage}`);
                },
              }}
              onChangeRowsPerPage={(lastRecord) => setLastRecord(lastRecord)}
              onChangePage={(offset) => changeOffset(offset)}
            />
          )}

          {/* <div className='sc-ezOQGI hoQsHK'>
            <button
              id='pagination-first-page'
              onClick={() => console.log('First page button clicked')}>
              First
            </button>
            <button onClick={() => console.log('Previous page button clicked')}>
              Previous
            </button>
            <button onClick={() => console.log('Next page button clicked')}>
              Next
            </button>
            <button
              onClick={() => {
                console.log('Last page button clicked');
                handleLastPageClick();
              }}>
              Last
            </button>
          </div> */}
        </div>
      ) : (
        <DetailPage
          setPage={setPage}
          dataS={dataS}
          keyNumbers={keyNumber}
          onClickSave={onClickSave}
          setVal={setVal}
          setIdKey={setIdKey}
          handleClose={handleClose}
          setOpenAction={setOpenAction}
          setIsCommonLoading={setIsCommonLoading}
          setRefreshList={setRefreshList}
          noteloading={noteloading}
          setNoteLoading={setNoteLoading}
          updatedDealSize={updatedDealSize}
          setUpdatedDealSize={setUpdatedDealSize}
          setAlertStatus={setAlertStatus}
          setAlertMessage={setAlertMessage}
          setModalIsOpen={setModalIsOpen}
          // noteType={noteType}
          setDistAccountNumber={setDistAccountNumber}
          leadSource={leadSource}
          setLeadRank={setLeadRank}
          leadRank={leadRank}
        />
      )}
    </>
  );
};
export default OpenLeadsNew;
